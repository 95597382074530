import User from '../../../../admin/user';
export default {
  name: 'consult',
  components: {
    User
  },
  props: ['wf_inprocess_dtl_id'],
  data() {
    return {
      consultIndex: null,
      showUserModal: false,
      respMessage:'',
      showAlert: false,
      isSuccess: false,
      loader: false,
      editMode: true,
      consultList: [
        {
          name: null,
          consultUserId: null,
          email: null,
          comments: null
        }
      ],
      consultFeilds: [
        {
          key: 'name'
        },
        {
          key: 'email'
        },
        {
          key: 'comments'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {},
  methods: {
    saveConsult() {
      const filterConsultArr = this.consultList.filter(
        data => data.consultUserId !== null
      );
      const consultUrsId = filterConsultArr.map(data => {
        return {
          consult_comment: data.comments,
          consult_user_Id: data.consultUserId
        };
      });
      const payload = {
        consult_comments:
          consultUrsId && consultUrsId.length > 0 ? consultUrsId : [],
        inprocess_dtl_id: this.wf_inprocess_dtl_id
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getConsult', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      if (this.editMode) {
        this.consultList.push({
          name: null,
          email: null,
          consultUserId: null,
          comments: null
        });
      }
    },
    removeRow(index) {
      this.consultList.splice(index, 1);
    },
    rowSelected() {},
    showHideEmpModal(flag, index) {
      if (flag) {
        this.consultIndex = index;
        this.showUserModal = true;
      } else {
        this.showUserModal = false;
      }
    },
    selectedUser(item) {
      this.consultList[this.consultIndex].name = item.name;
      this.consultList[this.consultIndex].consultUserId = item.user_id;
      this.consultList[this.consultIndex].email = item.user_email;
      this.showUserModal = false;
    }
  },
  beforeDestroy() {}
};
